import React from 'react'
import { useParams } from "react-router-dom";
import moment from 'moment'

export default function Confirmation({location}) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let entries = urlParams.entries()
    let data = {}
    for(const entry of entries) {
        data[entry[0]] = entry[1]
    }

    let date = moment(data.event_start_time).format('HH:mm A, dddd, MMMM D, Y');
    console.log(data)
    return (
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="complimentary_meeting">
                        <div class="row">
                            <div class="col-md-6 m-auto">
                                <div class="meeting_info_card confirm_meeting_card text-center">
                                    <div class="company_logo">
                                        <img src="/images/fbs_logo.png" alt="" />
                                        <h1 class="mt-2"><strong>Confirmed</strong></h1>
                                    </div>
                                    <p>You are scheduled with Federal Benefit Specialists.</p>
                                    <a class="add_to_calendar" href="<?php echo $url;?>">
                                    <img src="/images/plus_icon_6.png" alt="" />
                                    <p>Add to your calander</p>
                                    </a>
                                    <div class="confirm_meeting_border">
                                    <div><img src="/images/fade_border.png" alt="" /></div>
                                        <h1 class="text-left mt-2"><strong>Federal Employee Complimentary <br />
                                        Phone Meeting</strong>
                                        </h1>
                                        <div class="meeting_time">
                                        <h2 className='d-flex justify-content-start align-items-center'>
                                            <span><img src="/images/calendar_icon_2.png" alt="" /></span>
                                            {date} (30 Mins)
                                        </h2>
                                        </div>
                                        <div class="d-flex align-items-center">
                                        <h5><span><img style={{width: "30px"}} src="/images/world_icon.png" alt="" /></span></h5>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <h5><span><img style={{width: "30px"}} src="/images/phone_icon.png" alt="" /></span></h5>
                                    </div>
                                    <div><img src="/images/fade_border.png" alt="" /></div>
                                </div>
                                </div>
                                <div class="automated_scheduling">
                                <label>Simple. Automated. Scheduling.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    )
}
