import { useState, useEffect } from 'react';
import { InlineWidget } from "react-calendly";
import axios from 'axios';
import './style.css';

function Calendar() {
  const endpoint_url = "https://calendly.fedbenefitspecialists.com/";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)
  const [calendlyEventURL, setCalendlyEventURL] = useState(null)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ic = urlParams.get('ic')

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [windowWidth])

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getDates();
  }, []);

  const getDates = async () => {
    await setIsLoading(true)
    if(ic != null){
      await axios({
        url:endpoint_url+"/api.php?ic="+ic,
        method:"GET",
        headers:{
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }).then((response)=>{
        console.log(
          "data::",
          response.data,
          response.success
        )
        if(response.data.success == true){
          setCalendlyEventURL(response.data.data)
        } else {
          setCalendlyEventURL("https://calendly.com/appointments-113/federal-employee-complimentary-phone-meeting")
        }
      }).catch((error)=>{
        setCalendlyEventURL("https://calendly.com/appointments-113/federal-employee-complimentary-phone-meeting")
      });
    } else {
      setCalendlyEventURL("https://calendly.com/appointments-113/federal-employee-complimentary-phone-meeting")
    }
    await setIsLoading(false)
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {
              isLoading
              ? <div className='text-center'>
                <b>Please Wait...</b>
                <p>Loading Calendar</p>
              </div>
              : calendlyEventURL ? <InlineWidget url={calendlyEventURL} styles={{
                height: '780px'
              }}/> : ""
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Calendar;